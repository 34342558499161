import request from '@/utils/request.js'

// 获取填报率、准确率、柱状图、得分列表
export function workbenchAllApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-screen/companyCount',
    method: 'post',
    data: data
  })
}

// 获取填报率、准确率、柱状图、得分列表
export function workbenchAllApi2(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-screen/companyCount2',
    method: 'post',
    data: data
  })
}


// 获取公司填报列表
export function workbenchListApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-screen/company/list',
    method: 'post',
    data: data
  })
}