<template>
    <div>
        <div class="title-header">
            <!-- 标题 -->
            <div class="title-box">
                <el-row type="flex" justify="space-between" align="middle">
                    <el-col>
                        <el-row type="flex" align="middle">
                            <el-col :span="17">
                                <div class="title-tit">
                                    {{ survey.wjdoTitle }}
                                    <small class="fs-6">
                                        ({{ survey.wjdoDateBegin }} --
                                        {{ survey.wjdoDateEnd }})
                                    </small>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <!-- 搜索条件 -->
            <div class="header-box">
                <el-row type="flex">
                    <el-col>
                        <span class="header-title">参与企业：</span>
                        <el-select v-model="cityValue" filterable  placeholder="请选择">
                            <el-option
                                v-for="item in companyList"
                                :key="item.uscc"
                                :label="item.name"
                                :value="item.uscc"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 正文内容 -->
        <div class="row" id="app" style="margin-bottom: 50px"  element-loading-text="拼命加载中"  v-loading="contentLoadding">
            <!-- 中间echarts -->
            <div class="col-8">
                <!-- <h1>
                    {{ survey.wjdoTitle }}
                    
                </h1> -->
                <!-- <h2 class="text-center" v-if="companies.length > 0">
                    {{ companies.length }}家企业参与
                </h2> -->
                <el-row :gutter="24" style="margin: 0;">
                    <el-col :span="19">
                        <ul style="list-style: none; margin-top: 0">
                            <a id="top"></a>
                            <li v-for="(q, k) in question" :key="k">
                                <h4
                                    class="text-dark"
                                    v-if="q.code"
                                    :title="q.code"
                                    :id="q.title"
                                >
                                    <span v-if="q.index">{{ q.index }}、</span>
                                    [{{ q.code }}]{{ q.title }}
                                </h4>
                                <div
                                    class="row"
                                    v-if="
                                        q.input_type === 'radio' ||
                                        q.input_type === 'select'
                                    "
                                    style="background-color: #fff"
                                >
                                    <el-row :gutter="24">
                                        <el-col :span="15"
                                            ><div class="col-8">
                                                <table class="table">
                                                    <thead style="border:none;">
                                                        <tr>
                                                            <th
                                                                class="text-center"
                                                            >
                                                                编码
                                                            </th>
                                                            <th
                                                                class="text-end"
                                                            >
                                                                选项
                                                            </th>
                                                            <th>小计</th>
                                                            <th>
                                                                占本题参与企业比例
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody
                                                        v-if="q.input_options"
                                                    >
                                                        <tr
                                                            v-for="o in q
                                                                .input_options
                                                                .items"
                                                            :key="o.code"
                                                        >
                                                            <td
                                                                class="text-center"
                                                            >
                                                                {{ o.code }}
                                                            </td>
                                                            <td
                                                                class="text-end"
                                                            >
                                                                {{ o.text }}
                                                            </td>
                                                            <td
                                                                class="text-success handler"
                                                                @click="
                                                                    listAnswerByName(
                                                                        q.code,
                                                                        o.value,
                                                                        o.score
                                                                    )
                                                                "
                                                                :title="o.code"
                                                            >
                                                                {{ o.score }}
                                                            </td>
                                                            <td>
                                                                <div
                                                                    v-if="
                                                                        o.proportion
                                                                    "
                                                                >
                                                                    <div>
                                                                        <div>
                                                                            <el-progress
                                                                                show-text
                                                                                :stroke-width="
                                                                                    16
                                                                                "
                                                                                :percentage="
                                                                                    o.proportion
                                                                                "
                                                                            ></el-progress>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table></div
                                        ></el-col>
                                        <el-col :span="9">
                                            <div class="col-4">
                                                <div
                                                    :ref="q.code"
                                                    :id="q.code"
                                                    style="height: 300px"
                                                ></div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div
                                    class="row"
                                    v-else-if="q.input_type === 'checkbox'"
                                    style="background-color: #fff"
                                >
                                    <div class="col-12">
                                        <table
                                            class="table table-striped table-hover"
                                        >
                                            <thead
                                            >
                                                <tr>
                                                    <th class="text-center">
                                                        编码
                                                    </th>
                                                    <th class="text-end">
                                                        选项
                                                    </th>
                                                    <th>小计</th>
                                                    <th>占本题参与企业比例</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="q.input_options">
                                                <tr
                                                    v-for="o in q.input_options
                                                        .items"
                                                    :key="o.code"
                                                >
                                                    <td class="text-center">
                                                        {{ o.code }}
                                                    </td>
                                                    <td class="text-end">
                                                        {{ o.text }}
                                                    </td>
                                                    <td
                                                        class="text-success handler"
                                                        @click="
                                                            listAnswerByName(
                                                                q.code,
                                                                o.value,
                                                                o.score
                                                            )
                                                        "
                                                        :title="o.code"
                                                    >
                                                        {{ o.score }}
                                                    </td>
                                                    <td>
                                                        <div
                                                            class="row"
                                                            v-if="o.proportion"
                                                        >
                                                            <div class="col-8">
                                                                <div
                                                                    class="progress"
                                                                >
                                                                    <el-progress
                                                                        show-text
                                                                        :stroke-width="
                                                                            16
                                                                        "
                                                                        :percentage="
                                                                            o.proportion
                                                                        "
                                                                    ></el-progress>
                                                                    <!-- <div
                                                                        class="progress-bar bg-success"
                                                                        role="progressbar"
                                                                        :style="
                                                                            'width:' +
                                                                            o.proportion +
                                                                            '%;'
                                                                        "
                                                                        :aria-valuenow="
                                                                            o.proportion
                                                                        "
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                    ></div> -->
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-4">
                                                                {{
                                                                    o.proportion
                                                                }}%
                                                            </div> -->
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    v-else-if="
                                        q.input_type === 'text' ||
                                        q.input_type === 'textarea' ||
                                        q.input_type === 'number'
                                    "
                                    style="background-color: #fff"
                                >
                                    <div
                                        class="col-12"
                                        style="
                                            max-height: 300px;
                                            overflow: auto;
                                        "
                                    >
                                        <table
                                            class="table table-striped table-hover"
                                        >
                                            <thead>
                                                <tr
                                                    style="
                                                        border-bottom: 1px slo;
                                                    "
                                                >
                                                    <th class="text-center">
                                                        内容
                                                    </th>
                                                    <th>小计</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(a, k) in q.answer"
                                                    :key="k"
                                                >
                                                    <td
                                                        class="text-center"
                                                        v-html="a.value"
                                                    ></td>
                                                    <td
                                                        class="text-success handler"
                                                        @click="
                                                            listAnswerByName(
                                                                q.code,
                                                                a.value,
                                                                a.score
                                                            )
                                                        "
                                                        :title="a.name"
                                                        v-if="a.value"
                                                    >
                                                        {{ a.score }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div
                                    class="bg-success"
                                    style="padding: 10px"
                                    v-else
                                    v-html="q.title_as"
                                    :id="q.title"
                                ></div>
                            </li>
                        </ul>
                    </el-col>
                    <el-col :span="5">
                        <ul class="nav bg-light" id="menu" v-if="topMenu">
                            <li class="nav-item" @click="topMenuClick('#top')">
                                <i
                                    class="nav-li"
                                    :class="
                                        topMenuValue === '#top'
                                            ? 'nav-li-hover'
                                            : ''
                                    "
                                ></i>
                                <a
                                    class="nav-link h6 text-dark"
                                    :class="
                                        topMenuValue === '#top' ? 'a-hover' : ''
                                    "
                                    aria-current="page"
                                    href="#top"
                                    >常规问卷</a
                                >
                            </li>
                            <li
                                class="nav-item"
                                v-for="(q, k) in topMenu"
                                :key="k"
                                @click="topMenuClick('#' + q.title)"
                            >
                                <i
                                    class="nav-li"
                                    :class="
                                        topMenuValue === '#' + q.title
                                            ? 'nav-li-hover'
                                            : ''
                                    "
                                ></i>
                                <a
                                    :class="
                                        topMenuValue === '#' + q.title
                                            ? 'a-hover'
                                            : ''
                                    "
                                    aria-current="page"
                                    :href="'#' + q.title"
                                    >{{ q.title }}</a
                                >
                            </li>
                        </ul></el-col
                    >
                </el-row>

                <!-- <div id="statisticsHour" style="height: 300px"></div>
                <div id="statisticsDate" style="height: 300px"></div> -->
            </div>
        </div>
        <!-- <div class="flex">
            <div class="btn-group-vertical" style="width: 100%">
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadAnalysis()"
                >
                    <i class="fa fa-download"></i> 下载分析结果
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadCompany()"
                >
                    <i class="fa fa-download"></i> 下载参与企业及联系方式
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="doDownloadAnswer(0)"
                >
                    <i class="fa fa-download"></i> 下载所有问卷数据
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="doDownloadAnswer(1)"
                >
                    <i class="fa fa-download"></i> 下载所有问卷数据（编码）
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadinformationmanagement()"
                >
                    <i class="fa fa-download"></i> 双稳平台推送信息管理
                </el-button>
            </div>
        </div> -->
    </div>
</template>
<script>
// import { getSurvey, getSurveyScore } from '../../../api/wenjuan.js'
import { surveyScoreApi } from '@/api/analysisResultApi'
import { workbenchListApi } from '@/api/workbench'
import * as Chart from 'echarts'
export default {
    components: {},
    data() {
        return {
            contentLoadding:false,
            wjdoId:'',
            companyList: [],
            cityValue:'',
            survey: {},
            companies: [], //参与企业
            question: [], // 图标数据
            topMenuValue: '',
            haveEc: ['radio', 'checkbox', 'select'],
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}%',
                },
                legend: {
                    show: false,
                    top: 'center',
                    left: '5%',
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                series: [
                    {
                        name: '占本题参与企业比例(%)',
                        type: 'pie',
                        radius: ['28%', '60%'],
                        avoidLabelOverlap: true,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '18',
                                fontWeight: 'bold',
                            },
                        },
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    formatter: '{b} : {c}%',
                                },
                                labelLine: { show: true },
                            },
                        },
                        labelLine: {
                            show: true,
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80,
                        },
                        data: [],
                    },
                ],
            },
        }
    },
    computed: {
        topMenu() {
            var m = []
            if (this.question) {
                for (var q of this.question) {
                    if (q && !q.code) {
                        m.push(q)
                    }
                }
            }
            return m
        },
    },
    watch:{
        cityValue(){
            this.contentLoadding=true
            this.getSurveyScoreFun()
        },
    },
    created() {
        this.contentLoadding=true
        // this.uscc = this.$route.query.uscc
        this.cityValue = this.$route.query.uscc
        this.wjdoId = this.$route.query.id
        this.workbenchListFun()
        this.getSurveyScoreFun()
        
    },
    methods: {
        // companyList 获取改问卷下的公司信息
        async workbenchListFun(){
            var start =  0
            var params = {
                start: start,
                length: 9999,
                draw: 1,
                wjdoId: this.wjdoId,
                type:'1'

            }
            // var p = Object.assign(params, this.formInline)
            var res = await workbenchListApi(params)
            this.companyList = res.data? res.data:[]
            // console.log('公司填报数据', res);
            // this.tableData = res.data
            // this.pageparm.total = res.recordsTotal
        },
         async getSurveyScoreFun() {
          var params = {
              group: '',
              city: '',
              job: '',
              uscc: this.cityValue,
          }
          var getSurveyScoreRes = await surveyScoreApi(this.$route.query.id, params)
        //   console.log('getSurveyScoreRes', getSurveyScoreRes)
        this.survey = getSurveyScoreRes.data
          this.question = getSurveyScoreRes.data.survey.questions
          //不写在这里会丢失html属性
          this.$nextTick(function () {
              //渲染
              for (var q of this.question) {
                  if (
                      q.input_type &&
                      this.haveEc.indexOf(q.input_type) > -1
                  ) {
                      this.doEcharts(q.code, q.echarts)
                  }
              }
              this.contentLoading = false
          })
          this.contentLoadding=false
      },
      doEcharts(code, data) {
          //console.log("echarts", code, data, this.option, this.$root.echarts)
          var el = document.getElementById(code)
          if (!el) {
              return
          }
          // var myChart = this.$root.echarts.init(el)
          var myChart = Chart.init(el)

          if (!data || data.length === 0) {
              myChart.dispose()
              return
          }

          var option = this.option
          option.series[0].data = data
          if (option && typeof option === 'object') {
              myChart.setOption(option)
          }
      },
        //查询参与企业
        async listAnswerByName(q, o, score) {
            console.log('q, o, score', q, o, score)
            // if (score <= 0) {
            //     return
            // }
            // var search = {}
            // search.question = q
            // search.option = o
            // search.group = this.search.group
            // search.city = this.search.city
            // search.job = this.search.job

            // var _this = this
            // layer.msg("数据请求中")
            // axios.get('/api/analysis/answer/company/' + this.id, {params: search})
            //     .then(res => {
            //         _this.answerCompany = res.data
            //         layer.closeAll()

            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
        },
        topMenuClick(title) {
            console.log('title', title)
            this.topMenuValue = title
        },
        // 下载分析结果
        downloadAnalysis() {},
        // 下载参与企业及联系方式
        downloadCompany() {},
        // 下载所有问卷数据:0   下载所有问卷数据（编码）:1
        doDownloadAnswer() {},
        // 双稳平台推送信息管理
        downloadinformationmanagement() {},
    },
}
</script>
<style scoped>
.table {
    width: 100%;
    border-collapse: collapse;
    /* background-color: #fff; */
}
.text-center {
    text-align: center;
}
tr {
    height: 40px;
}
th {
    padding: 0;
    background-color: rgba(250, 250, 250, 1);
}
td {
    border-bottom: 1px solid #ccc;
    padding: 0;
}
::v-deep .el-progress {
    display: flex;
}
/* ::v-deep .el-progress-bar {
    padding-right: 10px;
    flex-grow: 1;
}

::v-deep .el-progress__text {
    width: 50px;
    flex-shrink: 0;
} */
.nav {
    position: fixed;
    right: 50;
}
.nav-item {
    list-style-type: none;
    margin-bottom: 10px;
}
a {
    color: #000;
    text-decoration: none;
    margin-left: 7px;
}
.a-hover {
    color: rgb(24, 144, 255);
}
.nav-li {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #474747;
}
.nav-li-hover {
    background-color: rgb(24, 144, 255);
}
.flex {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 18px;
    padding: 10px;
    background-color: #fff;
    text-align: center;
}
/* 头部导航 */
.title-header {
    background-color: #fff;
    position: sticky;
    top: 0px;
    z-index: 99;
    /* padding: 20px; */
}
.title-box {
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.header-box {
    padding: 10px 20px 10px 40px;
}
.header-title {
    font-size: 14px;
}
.title-tit {
    font-size: 20px;
    font-weight: bold;
}
</style>
