var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-header"},[_c('div',{staticClass:"title-box"},[_c('el-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('el-col',[_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":17}},[_c('div',{staticClass:"title-tit"},[_vm._v(" "+_vm._s(_vm.survey.wjdoTitle)+" "),_c('small',{staticClass:"fs-6"},[_vm._v(" ("+_vm._s(_vm.survey.wjdoDateBegin)+" -- "+_vm._s(_vm.survey.wjdoDateEnd)+") ")])])])],1)],1)],1)],1),_c('div',{staticClass:"header-box"},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('span',{staticClass:"header-title"},[_vm._v("参与企业：")]),_c('el-select',{attrs:{"filterable":"","placeholder":"请选择"},model:{value:(_vm.cityValue),callback:function ($$v) {_vm.cityValue=$$v},expression:"cityValue"}},_vm._l((_vm.companyList),function(item){return _c('el-option',{key:item.uscc,attrs:{"label":item.name,"value":item.uscc}})}),1)],1)],1)],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.contentLoadding),expression:"contentLoadding"}],staticClass:"row",staticStyle:{"margin-bottom":"50px"},attrs:{"id":"app","element-loading-text":"拼命加载中"}},[_c('div',{staticClass:"col-8"},[_c('el-row',{staticStyle:{"margin":"0"},attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":19}},[_c('ul',{staticStyle:{"list-style":"none","margin-top":"0"}},[_c('a',{attrs:{"id":"top"}}),_vm._l((_vm.question),function(q,k){return _c('li',{key:k},[(q.code)?_c('h4',{staticClass:"text-dark",attrs:{"title":q.code,"id":q.title}},[(q.index)?_c('span',[_vm._v(_vm._s(q.index)+"、")]):_vm._e(),_vm._v(" ["+_vm._s(q.code)+"]"+_vm._s(q.title)+" ")]):_vm._e(),(
                                    q.input_type === 'radio' ||
                                    q.input_type === 'select'
                                )?_c('div',{staticClass:"row",staticStyle:{"background-color":"#fff"}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":15}},[_c('div',{staticClass:"col-8"},[_c('table',{staticClass:"table"},[_c('thead',{staticStyle:{"border":"none"}},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" 编码 ")]),_c('th',{staticClass:"text-end"},[_vm._v(" 选项 ")]),_c('th',[_vm._v("小计")]),_c('th',[_vm._v(" 占本题参与企业比例 ")])])]),(q.input_options)?_c('tbody',_vm._l((q
                                                            .input_options
                                                            .items),function(o){return _c('tr',{key:o.code},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(o.code)+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(o.text)+" ")]),_c('td',{staticClass:"text-success handler",attrs:{"title":o.code},on:{"click":function($event){return _vm.listAnswerByName(
                                                                    q.code,
                                                                    o.value,
                                                                    o.score
                                                                )}}},[_vm._v(" "+_vm._s(o.score)+" ")]),_c('td',[(
                                                                    o.proportion
                                                                )?_c('div',[_c('div',[_c('div',[_c('el-progress',{attrs:{"show-text":"","stroke-width":16,"percentage":o.proportion}})],1)])]):_vm._e()])])}),0):_vm._e()])])]),_c('el-col',{attrs:{"span":9}},[_c('div',{staticClass:"col-4"},[_c('div',{ref:q.code,refInFor:true,staticStyle:{"height":"300px"},attrs:{"id":q.code}})])])],1)],1):(q.input_type === 'checkbox')?_c('div',{staticClass:"row",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-striped table-hover"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" 编码 ")]),_c('th',{staticClass:"text-end"},[_vm._v(" 选项 ")]),_c('th',[_vm._v("小计")]),_c('th',[_vm._v("占本题参与企业比例")])])]),(q.input_options)?_c('tbody',_vm._l((q.input_options
                                                    .items),function(o){return _c('tr',{key:o.code},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(o.code)+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(o.text)+" ")]),_c('td',{staticClass:"text-success handler",attrs:{"title":o.code},on:{"click":function($event){return _vm.listAnswerByName(
                                                            q.code,
                                                            o.value,
                                                            o.score
                                                        )}}},[_vm._v(" "+_vm._s(o.score)+" ")]),_c('td',[(o.proportion)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"progress"},[_c('el-progress',{attrs:{"show-text":"","stroke-width":16,"percentage":o.proportion}})],1)])]):_vm._e()])])}),0):_vm._e()])])]):(
                                    q.input_type === 'text' ||
                                    q.input_type === 'textarea' ||
                                    q.input_type === 'number'
                                )?_c('div',{staticClass:"row",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"col-12",staticStyle:{"max-height":"300px","overflow":"auto"}},[_c('table',{staticClass:"table table-striped table-hover"},[_c('thead',[_c('tr',{staticStyle:{"border-bottom":"1px slo"}},[_c('th',{staticClass:"text-center"},[_vm._v(" 内容 ")]),_c('th',[_vm._v("小计")])])]),_c('tbody',_vm._l((q.answer),function(a,k){return _c('tr',{key:k},[_c('td',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(a.value)}}),(a.value)?_c('td',{staticClass:"text-success handler",attrs:{"title":a.name},on:{"click":function($event){return _vm.listAnswerByName(
                                                            q.code,
                                                            a.value,
                                                            a.score
                                                        )}}},[_vm._v(" "+_vm._s(a.score)+" ")]):_vm._e()])}),0)])])]):_c('div',{staticClass:"bg-success",staticStyle:{"padding":"10px"},attrs:{"id":q.title},domProps:{"innerHTML":_vm._s(q.title_as)}})])})],2)]),_c('el-col',{attrs:{"span":5}},[(_vm.topMenu)?_c('ul',{staticClass:"nav bg-light",attrs:{"id":"menu"}},[_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.topMenuClick('#top')}}},[_c('i',{staticClass:"nav-li",class:_vm.topMenuValue === '#top'
                                        ? 'nav-li-hover'
                                        : ''}),_c('a',{staticClass:"nav-link h6 text-dark",class:_vm.topMenuValue === '#top' ? 'a-hover' : '',attrs:{"aria-current":"page","href":"#top"}},[_vm._v("常规问卷")])]),_vm._l((_vm.topMenu),function(q,k){return _c('li',{key:k,staticClass:"nav-item",on:{"click":function($event){return _vm.topMenuClick('#' + q.title)}}},[_c('i',{staticClass:"nav-li",class:_vm.topMenuValue === '#' + q.title
                                        ? 'nav-li-hover'
                                        : ''}),_c('a',{class:_vm.topMenuValue === '#' + q.title
                                        ? 'a-hover'
                                        : '',attrs:{"aria-current":"page","href":'#' + q.title}},[_vm._v(_vm._s(q.title))])])})],2):_vm._e()])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }